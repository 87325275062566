@tailwind base;
@tailwind components;
@tailwind utilities;

@tailwind base;
@tailwind components;
@tailwind utilities;
@import '@fontsource/poppins'; /* Default weight (400) */

@layer base {
  h1, h2, h3, h4, h5, h6 {
    @apply font-sans;  /* Tailwind font class (replace 'font-sans' if needed) */
    font-family: 'Outfit', sans-serif;  /* Replace 'YourFont' with your custom font */
  }

  p {
    @apply font-sans;  /* Tailwind font class (replace 'font-sans' if needed) */
    font-family: 'Outfit', sans-serif;  /* Replace 'YourFont' with your custom font */
  }
}
